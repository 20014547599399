import React from "react";
import ReactDOM from "react-dom";
import "./reset.css";
import "./index.css";
import Home from "./components/Home";
import Game from "./components/Game";
import Leaderboard from "./components/Leaderboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/play" component={Game} />
            <Route exact path="/leaderboard" component={Leaderboard} />
            <Route render={() => <h1>40404040404040404</h1>} />
          </Switch>
        </div>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
