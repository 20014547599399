import React from "react";
import { getPage } from "../utils/api";

export default class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scores: null
    };
  }

  componentDidMount() {
    const { scores, morePages, pageSize, pageNum } = this.props;
    if (scores) {
      this.setState({
        scores: scores,
        morePages: morePages,
        pageSize: pageSize,
        pageNum: pageNum
      });
    } else {
      getPage(1).then(res => {
        this.setState({
          scores: res.scores,
          morePages: res.more_pages,
          pageSize: res.page_size,
          pageNum: 1
        });
      });
    }
    // getScores(1, 10)
    //   .then(scores => {
    //     this.setState({ scores: scores.scores });
    //   })
    //   .catch(e => {
    //     console.warn("Error getting game: ", e);
    //   });
  }

  handleLeftClick = () => {
    const { pageNum } = this.state;
    this.setState({ scores: null }, () => {
      getPage(pageNum - 1).then(res => {
        this.setState({
          scores: res.scores,
          morePages: res.more_pages,
          pageSize: res.page_size,
          pageNum: pageNum - 1
        });
      });
    });
  };

  handleRightClick = () => {
    const { pageNum } = this.state;
    this.setState({ scores: null }, () => {
      getPage(pageNum + 1).then(res => {
        this.setState({
          scores: res.scores,
          morePages: res.more_pages,
          pageSize: res.page_size,
          pageNum: pageNum + 1
        });
      });
    });
  };

  render() {
    const { scores, morePages, pageNum } = this.state;
    const { scoreID } = this.props;
    if (!scores) {
      return <h3>LOADING...</h3>;
    }
    return (
      <div className="leaderboard">
        <h1 className="leaderboard-title">LEADERBOARD</h1>
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>PLAYER</th>
              <th>LEVEL</th>
              <th>TOTAL TIME</th>
              <th>TIME LEFT</th>
            </tr>
          </thead>
          <tbody>
            {scores.map(score => (
              <tr
                className={scoreID === score.id ? "leaderboard-game-row" : ""}
                key={score.id}
              >
                <td>{score.placement}</td>
                <td style={{ fontWeight: "bold" }}>{score.player}</td>
                <td>{score.level}</td>
                <td>{score.time}</td>
                <td>{score.time_left}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {(pageNum !== 1 || morePages) && (
          <div className="leaderboard-nav">
            {pageNum !== 1 && (
              <button
                className="align-left"
                onClick={this.handleLeftClick}
                disabled={!scores}
              >
                &lt;&lt;
              </button>
            )}

            {morePages && (
              <button
                className="align-right"
                onClick={this.handleRightClick}
                disabled={!scores}
              >
                &gt;&gt;
              </button>
            )}
            <div style={{ clear: "both" }}></div>
          </div>
        )}
      </div>
    );
  }
}
