import React from "react";
import Board from "./Board";
import Leaderboard from "./Leaderboard";
import { getGame, newScore } from "../utils/api";

class GameOver extends React.Component {
  render() {
    const { scores, scoreID, morePages, pageSize, pageNum } = this.props;
    return (
      <>
        <Leaderboard
          scores={scores}
          scoreID={scoreID}
          morePages={morePages}
          pageSize={pageSize}
          pageNum={pageNum}
        />
        <h2>GAME OVER</h2>
        <p>press 'r' to restart</p>
      </>
    );
  }
}

export default class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 1,
      time: 0,
      timeLeft: 0,
      game: null,
      scoreID: null,
      scores: null,
      gameOver: false,
      playing: false,
      player: "",
      submittingScore: false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false);
    this.getNewGame();
  }

  getNewGame = () => {
    getGame()
      .then(game => {
        this.setState({ game: game.levels });
      })
      .catch(e => {
        console.warn("Error getting game: ", e);
      });
  };

  handleKeyPress = e => {
    if (e.defaultPrevented) {
      return;
    }
    let key = e.key || e.keyCode;
    if (this.state.gameOver && (key === "r" || key === "R" || key === 82)) {
      this.setState({ level: 1, time: 0, gameOver: false, levels: null });
      this.getNewGame();
    }
  };

  handleSetGameOver = outOfTime => {
    const { player, level, time, timeLeft } = this.state;
    this.setState({ game: null, gameOver: true, submittingScore: true }, () => {
      newScore(player, level, time / 1000, outOfTime ? 0 : timeLeft / 1000)
        .then(res => {
          this.setState({
            scores: res.scores,
            scoreID: res.id,
            morePages: res.more_pages,
            pageSize: res.page_size,
            pageNum: res.page_num,
            submittingScore: false
          });
        })
        .catch(e => {
          console.warn("Error submitting new score: ", e);
        });
    });
  };

  handleNextLevel = () => {
    if (this.state.level === this.state.game.length - 1) {
      this.setState({
        gameOver: true
      });
    }
    this.setState(prevState => ({
      level: prevState.level + 1
    }));
  };

  handleAddTime = t => {
    this.setState(prevState => ({
      time: prevState.time + t
    }));
  };

  handleTimeLeftChange = t => {
    this.setState({ timeLeft: t });
  };

  render() {
    const {
      time,
      timeLeft,
      level,
      game,
      gameOver,
      player,
      playing,
      scores,
      scoreID,
      morePages,
      pageSize,
      pageNum,
      submittingScore
    } = this.state;
    if (submittingScore) {
      return <h2>SUBMITTING SCORE...</h2>;
    }
    if (gameOver) {
      return (
        <GameOver
          scores={scores}
          scoreID={scoreID}
          morePages={morePages}
          pageSize={pageSize}
          pageNum={pageNum}
          player={player}
          level={level}
          time={time}
          timeLeft={timeLeft}
        />
      );
    }
    if (game === null) {
      return <h1>Loading...</h1>;
    }
    if (playing) {
      return (
        <Board
          onAddTime={this.handleAddTime}
          onTimeLeftChange={this.handleTimeLeftChange}
          setGameOver={this.handleSetGameOver}
          game={game}
          level={level}
          nextLevel={this.handleNextLevel}
        />
      );
    }
    return (
      <div className="new-game">
        <Leaderboard />
        <form>
          <input
            className="name-input"
            type="text"
            name="playerName"
            value={player}
            maxLength={20}
            placeholder="YOUR NAME"
            onChange={e => this.setState({ player: e.target.value })}
          />
          <input
            type="submit"
            value="PLAY"
            className="play-button"
            onClick={() => this.setState({ playing: true })}
            disabled={player.trim() === "" ? true : false}
          />
        </form>
      </div>
    );
  }
}
