import React from "react";

export default class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  handleClick = () => {
    if (this.props.onCellClick(this.props.char, this.state.selected)) {
      this.setState({ selected: true });
    }
  };

  render() {
    const { selected } = this.state;
    const { char } = this.props;
    return (
      <div
        className={selected ? "cell cell-selected" : "cell"}
        /* style={
          char === "M" || char === "W" ? { textDecoration: "underline" } : null
        } */
        onClick={this.handleClick}
      >
        {char}
      </div>
    );
  }
}
