import React from "react";
import Cell from "./Cell";

const second = 1000;
const interval = second / 60;

function Timer(props) {
  return <div className="timer">{(props.time / 1000).toFixed(4)}</div>;
}

export default class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 10 * second,
      wordCompleted: "",
      wordNotCompleted: this.props.game[this.props.level - 1].word,
      letters: this.props.game[this.props.level - 1].letters,
      size: Math.sqrt(this.props.game[this.props.level - 1].letters.length)
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(this.timer, interval);
  }

  timer = () => {
    if (this.state.time - interval <= 0) {
      this.props.setGameOver(true);
      return;
    }
    this.setState(
      prevState => ({
        time: prevState.time - interval
      }),
      () => {
        this.props.onTimeLeftChange(this.state.time);
        this.props.onAddTime(interval);
      }
    );
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleCellClick = (char, selected) => {
    if (selected) return false;
    let { wordCompleted, wordNotCompleted } = this.state;
    const { setGameOver } = this.props;
    if (char !== wordNotCompleted[0]) {
      setGameOver();
      return false;
    }
    if (wordNotCompleted.length === 1) {
      this.nextLevel();
      return true;
    }
    this.setState({
      wordCompleted: (wordCompleted += wordNotCompleted[0]),
      wordNotCompleted: wordNotCompleted.substr(1)
    });
    return true;
  };

  nextLevel = () => {
    this.props.nextLevel();
    if (this.props.level === this.props.game.length) {
      return;
    }
    this.setState(prevState => ({
      time:
        this.props.game[this.props.level - 1].word.length * second +
        prevState.time -
        (this.props.level - (1 / 10) * second),
      wordCompleted: "",
      wordNotCompleted: this.props.game[this.props.level].word,
      letters: this.props.game[this.props.level].letters,
      size: Math.sqrt(this.props.game[this.props.level].letters.length)
    }));
  };

  render() {
    const { level } = this.props;
    const { wordCompleted, wordNotCompleted, letters, size, time } = this.state;

    return (
      <>
        <Timer time={time} />
        <div className="word">
          <span className="selected">{wordCompleted}</span>
          {wordNotCompleted}
        </div>
        <div
          className="board"
          style={{
            gridTemplateColumns: `repeat(${size}, 49px)`,
            gridTemplateRows: `repeat(${size}, 49px)`
          }}
        >
          {letters.split("").map((char, index) => (
            <Cell
              key={`${level}${index}`}
              char={char}
              onCellClick={this.handleCellClick}
            />
          ))}
        </div>
        <div className="level">LEVEL {level}</div>
      </>
    );
  }
}
