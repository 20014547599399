export function getGame() {
  return fetch("/api/game")
    .then(res => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      return res;
    })
    .then(res => res.json())
    .then(game => {
      if (!game.levels) {
        throw new Error("Error retrieving levels");
      }
      return game;
    });
}

export function getScores(low, high) {
  return fetch(`/api/scores?low=${low}&high=${high}`)
    .then(res => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      return res;
    })
    .then(res => res.json())
    .then(scores => {
      if (!scores.scores) {
        throw new Error("Error retrieving scores");
      }
      return scores;
    });
}

export function newScore(player, level, time, timeLeft) {
  return fetch(
    `/api/new_score?player=${player}&level=${level}&time=${time.toFixed(
      4
    )}&time_left=${timeLeft.toFixed(4)}`
  )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      return res;
    })
    .then(res => res.json())
    .then(res => {
      if (!res.id || !res.scores || !res.page_size || !res.page_num) {
        throw new Error("Error submitting new score");
      }
      return res;
    });
}

export function getPage(num, size) {
  return fetch(`/api/get_page?page=${num}`)
    .then(res => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      return res;
    })
    .then(res => res.json())
    .then(res => {
      if (
        !res.scores ||
        res.more_pages === undefined ||
        res.page_size === undefined
      ) {
        console.log(res);
        throw new Error("Error retrieving page");
      }
      return res;
    });
}
