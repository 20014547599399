import React from "react";

export default class Home extends React.Component {
  render() {
    return (
      <>
        <p>Home is where you're a Heartis.</p>
      </>
    );
  }
}
